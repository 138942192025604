// src/pages/Services.js

import React from "react";
import ServiceCard from "../components/services/ServiceCard";
import Layout from "../components/Layout/Layout";
import { motion } from "framer-motion"; // Import motion for stagger effect

const servicesData = [
  {
    title: "Custom Software Development",
    description: "We specialize in developing bespoke software solutions tailored to your business needs. Our team of experienced developers ensures that every solution seamlessly integrates with your systems, optimizes performance, and scales with your growth.",
    points: [
      "Design and develop custom software applications.",
      "Integrate software with existing systems and platforms.",
      "Optimize performance and scalability.",
      "Provide ongoing support and maintenance."
    ],
    image:"/image/software.png" // Replace with actual image path
  },
  {
    title: "Web Design and App Development",
    description: "We craft visually stunning websites and innovative mobile applications that are both aesthetically pleasing and functionally robust. Our team focuses on delivering a seamless user experience across all devices.",
    points: [
      "Develop responsive websites and mobile applications.",
      "Create content management systems (CMS) for easy updates.",
      "Ensure cross-platform compatibility and performance optimization."
    ],
    image: "/image/app.png" // Replace with actual image path
  },
  {
    title: "UX/UI Design",
    description: "Our UX/UI design services focus on creating intuitive, user-centered interfaces that enhance engagement and satisfaction.",
    points: [
      "Conduct user research and usability testing.",
      "Design wireframes, prototypes, and high-fidelity interfaces.",
      "Optimize user journeys to enhance engagement and conversion rates."
    ],
    image: "/image/uiux.png" // Replace with actual image path
  },
  {
    title: "IT Consulting",
    description: "Our IT consulting services are designed to help you leverage technology to achieve your business goals.",
    points: [
      "Assess and optimize your current IT infrastructure.",
      "Develop and implement IT strategies that support business growth.",
      "Provide cybersecurity assessments and solutions.",
      "Offer guidance on cloud migration, software selection, and IT project management."
    ],
    image: "/image/consulting.png" // Replace with actual image path
  },
];

const Services = () => {
  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative bg-gray-100 py-12">
        <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('path_to_hero_image')" }}>
          <div className="bg-primaryBlue bg-opacity-70 rounded-2xl h-full w-full"></div>
        </div>
        <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
          <h1 className="text-5xl font-bold text-white">Our Services</h1>
        </div>
      </section>

      {/* Services Section with Staggered Animation */}
      <section className="py-12 bg-white">
        <motion.div
          className="max-w-screen-xl mx-auto space-y-12"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 1, // Add a delay between each child animation
              },
            },
          }}
        >
          {servicesData.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              points={service.points}
              image={service.image}
              reverse={index % 2 !== 0} // Alternating layout
            />
          ))}
        </motion.div>
      </section>
    </Layout>
  );
};

export default Services;
