// src/components/ProductCard.js

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { motion } from "framer-motion"; // Import motion for animations

const ProductCard = ({ title, description, image, productId, reverse }) => {
  // Define animation variants for Framer Motion
  const variants = {
    hidden: (reverse) => ({
      opacity: 0,
      x: reverse ? 100 : -100, // Comes from right if reverse, from left if not
    }),
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.div
      className={`flex flex-col ${reverse ? "md:flex-row-reverse" : "md:flex-row"} items-center`}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }} // Animate when 30% of the card is visible
      custom={reverse}
      variants={variants}
    >
      {/* Image Section */}
      <div className="md:w-1/2 p-6">
        <div className="bg-gray-300 w-full h-64 md:h-80 rounded-lg flex items-center justify-center">
          {/* <p className="text-gray-500">Image Placeholder (640x360)</p> Replace with actual image */}
          <img src={image} />
        </div>
      </div>

      {/* Text Section */}
      <div className="md:w-1/2 p-6">
        <h3 className="text-3xl font-semibold text-blue-600 mb-4">{title}</h3>
        <p className="text-gray-700 mb-4">{description}</p>

        {/* More Button Inside the Card */}
        <div className="mt-6">
          <Link
            to={`/product/${productId}`} // Use productId for dynamic URL
            className="inline-block bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700"
          >
            More
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired, // For dynamic linking to ProductDetails
  reverse: PropTypes.bool,
};

export default ProductCard;
