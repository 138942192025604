import React from "react";
import Layout from "../components/Layout/Layout";
import Teams from "../components/Home/Teams";

function Team() {
  return (
    <Layout>
     <Teams/>
    </Layout>
  );
}

export default Team;
