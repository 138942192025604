// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import BookConsultancyForm from "./pages/Book-consultancy";
import Services from "./pages/Services";
import Consultancy from "./pages/Consulting";
import About from "./pages/About";
import Products from "./pages/Products";
import HospitalityProductDetails from "./pages/HospitalityProductDetails";
import EducationProductDetails from "./pages/EducationProductDetails";
import NFTMarketplaceProductDetails from "./pages/NFTMarketplaceProductDetails";
import GamesCasinoProductDetails from "./pages/GamesCasinoProductDetails";
import Team from "./pages/team";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/Book-consultancy" element={<BookConsultancyForm />} />
          <Route path="/consultancy" element={<Consultancy />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/hospitality" element={<HospitalityProductDetails />} />
          <Route path="/product/nftMarketplace" element={<NFTMarketplaceProductDetails />} />
          <Route path="/team" element={<Team />} />
          <Route path="/product/education" element={<EducationProductDetails />} />
          <Route path="/product/gamesCasino" element={<GamesCasinoProductDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
