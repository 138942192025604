import React, { useEffect } from "react";
import Waves from "node-waves";

function ProcessTimeline() {
  // Initialize Waves effect
  useEffect(() => {
    Waves.attach(".wave-button", ["waves-light"]);
    Waves.init();
  }, []);

  return (
    <section className="py-16 bg-gray-50 relative" id="how-we-do-it">
      <div
        className="absolute inset-0 h-full w-full bg-white bg-[linear-gradient(to_right,#d3e6ff_1px,transparent_1px),linear-gradient(to_bottom,#d3e6ff_1px,transparent_1px)] bg-[size:24px_24px]"
      ></div>
      <div className="max-w-screen-xl mx-auto text-center px-4 relative z-10">
        <h2 className="text-4xl font-bold text-primaryBlue mb-12">
          How We Do It
        </h2>

        {/* Improved Grid Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
          {/* Step 1 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-left wave-button hover:shadow-2xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex items-center justify-center bg-primaryBlue text-white rounded-full w-12 h-12 text-lg font-semibold">
                1
              </div>
              <h3 className="text-2xl font-semibold">Requirement Analysis</h3>
            </div>
            <p className="text-gray-600">
              We work closely with your team to understand your business needs and define the requirements for your project.
            </p>
          </div>

          {/* Step 2 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-left wave-button hover:shadow-2xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex items-center justify-center bg-primaryBlue text-white rounded-full w-12 h-12 text-lg font-semibold">
                2
              </div>
              <h3 className="text-2xl font-semibold">Innovation Design</h3>
            </div>
            <p className="text-gray-600">
              Our expert designers create an innovative and customized solution to meet your business objectives.
            </p>
          </div>

          {/* Step 3 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-left wave-button hover:shadow-2xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex items-center justify-center bg-primaryBlue text-white rounded-full w-12 h-12 text-lg font-semibold">
                3
              </div>
              <h3 className="text-2xl font-semibold">Development</h3>
            </div>
            <p className="text-gray-600">
              Our development team brings the design to life by building a scalable and efficient solution tailored to your needs.
            </p>
          </div>

          {/* Step 4 */}
          <div className="bg-white rounded-lg shadow-lg p-8 text-left wave-button hover:shadow-2xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center space-x-4 mb-4">
              <div className="flex items-center justify-center bg-primaryBlue text-white rounded-full w-12 h-12 text-lg font-semibold">
                4
              </div>
              <h3 className="text-2xl font-semibold">Testing & Quality Assurance</h3>
            </div>
            <p className="text-gray-600">
              We thoroughly test your solution to ensure it meets the highest standards of quality and performance.
            </p>
          </div>
        </div>

        {/* Step 5 - Full Width */}
        <div className="mt-12 bg-white rounded-lg shadow-lg p-8 text-left wave-button hover:shadow-2xl transition-shadow duration-300 ease-in-out max-w-screen-md mx-auto">
          <div className="flex items-center space-x-4 mb-4">
            <div className="flex items-center justify-center bg-primaryBlue text-white rounded-full w-12 h-12 text-lg font-semibold">
              5
            </div>
            <h3 className="text-2xl font-semibold">Deployment & Support</h3>
          </div>
          <p className="text-gray-600">
            We deploy the solution and offer ongoing support to ensure it continues to perform at the highest level.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProcessTimeline;
