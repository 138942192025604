// src/components/services/ConsultingFocus.js

import React from "react";

const focusAreas = [
  { title: "AI/Big Data Consulting", description: "Explore AI and Big Data solutions for your business." },
  { title: "IoT Consulting", description: "Leverage IoT solutions to enhance your business operations." },
  { title: "ERP Consulting", description: "Streamline your business with ERP solutions tailored to your needs." },
  { title: "Cloud Consulting", description: "Optimize your cloud infrastructure for better performance and scalability." },
  { title: "DevOps Consulting", description: "Implement DevOps strategies to accelerate your software delivery pipeline." },
];

const ConsultingFocus = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
      {focusAreas.map((area, index) => (
        <div key={index} className="flip-card group">
          <div className="flip-card-inner">
            {/* Front Side */}
            <div className="flip-card-front bg-blue-500 text-white rounded-lg shadow-md flex flex-col items-center justify-center p-6">
              <div className="bg-gray-300 w-20 h-20 mb-4 rounded-full flex items-center justify-center">
                <p className="text-gray-500">Icon</p>
              </div>
              <h3 className="text-xl font-bold">{area.title}</h3>
            </div>

            {/* Back Side */}
            <div className="flip-card-back bg-white text-blue-500 rounded-lg shadow-md flex items-center justify-center p-6">
              <p className="text-center text-lg">{area.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConsultingFocus;
