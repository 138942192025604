// src/pages/About.js

import React from "react";
import Layout from "../components/Layout/Layout";
import ServiceCard from "../components/services/ServiceCard"; // Reusing ServiceCard component
import IconCard from "../components/services/IconCard"; // Reuse for core values

const About = () => {
  return (
    <Layout>
      {/* Hero Section with Subheader */}
      <section className="relative bg-gray-100 py-12 mb-8">
        <div className="absolute inset-0 bg-cover h-full w-full bg-center" style={{ backgroundImage: "url('image/about.png')"  }}>
          <div className="bg-primaryBlue bg-opacity-30 h-full w-full"></div>
        </div>
        <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
          <h1 className="text-5xl font-bold text-white mb-4">About</h1>
          <h2 className="text-2xl font-semibold text-white">Welcome to BLUEBLOCKSS LTD...</h2>
        </div>
      </section>

      {/* Why Choose Us & How Do We Do It Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Why Choose Us */}
          <div>
            <h3 className="text-3xl font-bold text-primaryBlue mb-4">WHY CHOOSE US</h3>
            <ul className="text-gray-700 space-y-4">
              <li>We Listen, Research, then Customize</li>
              <li>End-to-End capabilities to drive change</li>
              <li>Made-on-Demand platform updates ensuring technology stays up-to-date</li>
              <li>Extensive Experience: IT, Coding, Design & Innovation</li>
              <li>Optimizing IT Solutions using cutting-edge technologies</li>
              <li>We provide market-driven evidence-based decision-making solutions</li>
            </ul>
          </div>

          {/* How Do We Do It */}
          <div>
            <h3 className="text-3xl font-bold text-primaryBlue mb-4">HOW DO WE DO IT</h3>
            <ol className="text-gray-700 space-y-4 list-decimal list-inside">
              <li>We listen to your business needs and challenges</li>
              <li>Research and identify the suitable applications and tools for your business</li>
              <li>Propose solutions with a detailed time and cost estimation</li>
              <li>Implement custom solutions using best practices</li>
              <li>Provide ongoing support and training (Onshore & Offshore)</li>
            </ol>
          </div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto text-center">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Reuse the IconCard component for each value */}
            <IconCard title="COMMITMENT" icon="/image/quality.png" />
            <IconCard title="QUALITY" icon="/image/quality.png" />
            <IconCard title="INNOVATION" icon="/image/quality.png" />
            <IconCard title="INTEGRITY" icon="/image/quality.png" />
          </div>
        </div>
      </section>

      {/* Vision & Mission Section using ServiceCard */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto space-y-12">
          <ServiceCard
            title="VISION"
            description="To be the leading provider of innovative and sustainable IT solutions that empower businesses to thrive in the evolving digital landscape. We are committed to delivering excellence with integrity, ensuring our clients achieve their goals through cutting-edge technology."
            points={[]} // Empty points array since Vision doesn't have bullet points
            image="/image/vision.png" // Replace with actual image path
            reverse={false} // Keep normal order
          />
          <ServiceCard
            title="MISSION"
            description="Our mission is to deliver affordable, custom IT solutions that address complex business challenges. We strive for excellence in every service we offer, ensuring clients receive top-tier guidance and cutting-edge solutions that optimize their operations and productivity."
            points={[]} // Empty points array since Mission doesn't have bullet points
            image="/image/mission.png" // Replace with actual image path
            reverse={true} // Reverse order for the second card
          />
        </div>
      </section>

      {/* Footer already included in Layout component */}
    </Layout>
  );
};

export default About;
