// src/pages/Consulting.js

import React from "react";
import Layout from "../components/Layout/Layout";
import ServiceCard from "../components/services/ServiceCard";
import ConsultingFocus from "../components/services/ConsultingFocus";

const consultingData = [
  {
    title: "Empowering Your Business with Innovative IT Solutions",
    description: "We work closely with you to understand your specific needs and craft custom solutions that address your most pressing concerns. Whether it's streamlining operations, enhancing customer engagement, or scaling your IT infrastructure, we provide the expertise to turn your vision into reality.",
    points: [],
    image: "/image/empowering.png", // Replace with actual image path
  },
  {
    title: "Strategic IT Consulting for Optimal ROI",
    description: "We believe in aligning your business strategies with the latest technological trends to deliver high returns on your investments. By focusing on the 'Maximum Utilization of Available Resources,' we help you unlock hidden potential within your existing infrastructure, optimizing performance and driving growth.",
    points: [],
    image: "/image/strategic.png", // Replace with actual image path
  },
  {
    title: "Future-Proof Your Business",
    description: "Technology is constantly evolving, and so are the needs of your business. Our forward-thinking approach ensures that your IT strategies are not only effective today but are also adaptable to tomorrow's challenges.",
    points: [],
    image: "/image/future.png", // Replace with actual image path
  },
  {
    title: "Tailored Solutions for Unique Business Needs",
    description: "Every business is unique, and so are the challenges it faces. Our expert consultants work closely with you to understand your specific needs and craft custom solutions that address your most pressing concerns. Whether it's streamlining operations, enhancing customer engagement, or scaling your IT infrastructure, we provide the expertise to turn your vision into reality.",
    points: [],
    image: "/image/tailored.png", // Replace with actual image path
  },
];

const Consulting = () => {
  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative bg-gray-100 py-12">
  <div
    className="absolute inset-0 bg-cover bg-center"
    style={{ backgroundImage: "url('path_to_hero_image')" }}
  >
    <div className="bg-primaryBlue rounded-2xl bg-opacity-70 h-full w-full"></div>
  </div>
  <div className="relative z-10 max-w-screen-xl mx-auto text-center py-12">
    <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white">
      IT Consulting
    </h1>
  </div>
</section>


      {/* Consulting Content Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto space-y-12">
          {consultingData.map((consulting, index) => (
            <ServiceCard
              key={index}
              title={consulting.title}
              description={consulting.description}
              points={consulting.points}
              image={consulting.image}
              reverse={index % 2 !== 0} // Alternate the layout
            />
          ))}
        </div>
      </section>

      {/* Areas of Consulting Focus */}
      <section className="py-12 bg-gray-100 mb-8">
        <div className="max-w-screen-xl mx-auto text-center mb-12">
          <h2 className="text-3xl font-bold text-primaryBlue mb-8">Areas of our Consulting Focus</h2>
          <ConsultingFocus />
        </div>
      </section>

      {/* Free Consultation Section */}
      <section className="bg-primaryBlue bg-opacity-70 py-12 text-white text-center">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">FREE CONSULTATION</h2>
          <p className="text-lg mb-6">
            Do you have an idea and find it difficult to frame an IT strategy for your business transformation? Book a 30-minute free consultation with our experts today.
          </p>
          <a
            href="/Book-consultancy"
            className="bg-primaryBlue text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-100"
          >
            Free Consultation
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Consulting;
