import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

function HeroSection() {
  return (
    <section className="relative bg-gray-100 py-12">
        
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row items-center">
        {/* Text Section */}
        <div className="md:w-1/2 p-6 text-center md:text-left z-10">
          <h1
            className="text-4xl md:text-5xl font-bold text-secondaryWhite mb-4 transform transition-all duration-500 hover:scale-105"
          >
            Empowering Your IT Solutions
          </h1>
          <p
            className="text-lg text-secondaryWhite mb-6 transform transition-all duration-500 hover:scale-105"
          >
            We provide cutting-edge IT consulting services that help your business thrive.
          </p>
          <Link
            to="/Book-consultancy"
            className="bg-primaryBlue text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-600 transition-all duration-500"
          >
            Book Consultancy
          </Link>
        </div>

        {/* Background Image Section */}
        {/* <div className="md:w-1/2 p-6 relative">
          <div className="bg-cover bg-center w-full h-64 md:h-80 rounded-lg flex items-center justify-center relative z-0"> */}
            {/* Background Image */}
            <img
              src="/640-360.png" // Add your background image here
              alt="Empowering IT Solutions"
              className="absolute inset-0 w-full h-full sm:w-full overflow-hidden object-cover rounded-lg opacity-90"
            />
          {/* </div>
        </div> */}
      </div>
    </section>
  );
}

export default HeroSection;
